<template>
  <transition appear>
    <div class="cookie-banner" v-if="!accepted">
      <p>Questo sito consente l'invio di cookie analitici e di profilazione propri e di terze parti per migliorare i contenuti, per facilitare l'uso e per verificare il successo delle pubblicità presenti sul web. Per saperne di più o negare il consenso a tutti o ad alcuni cookie, clicca <a href="#" target="_blank">qui</a>. Cliccando su “OK” o accedendo a un qualunque elemento sottostante questo banner, acconsenti all’uso dei cookie.</p>
      <button class="confirm-button" @click="acceptCookie">OK</button>
    </div>
  </transition>
</template>

<script>
import Platform from '../platform'
import VueCookie from 'vue-cookie'

export default {
  name: 'CookieBanner',

  props: {
    loaded: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      accepted: true
    }
  },

  mounted: function () {
    if (!Platform.prerenderer) {
      this.accepted = VueCookie.get('cookie-law-accepted')
    }
  },

  methods: {
    acceptCookie: function () {
      this.accepted = true

      VueCookie.set('cookie-law-accepted', true, 7)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'variables';

.cookie-banner {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 25px 30px;
  z-index: 2000;
  color: #e9e3c7;
  background-color: rgba(6, 13, 11, 0.9);
  width: 825px;
  border: solid 1px $c-blackmirror-blue;
  text-align: center;

  @include breakpoint($sm-down) {
    width: calc(100% - 2px);
    padding: 0 0 15px 0;
    bottom: 0;
  }

  a {
    color: $c-blackmirror-blue;
    text-decoration: underline;
  }

  p {
    color: $c-blackmirror-blue;

    font-weight: 400;

    margin: auto;

    font-size: 11px;
    font-stretch: condensed;
    line-height: 16px;
    letter-spacing: 1px;
    text-align: center;

    margin-bottom: 20px;

    @include breakpoint($sm-down) {
      padding: 20px 25px 0;
    }

    @include breakpoint($sm-down) {
      padding: 15px 15px 0;
    }
  }

  .confirm-button {
    width: 150px;
    height: 35px;

    margin: auto;

    line-height: 29px;

    border: 1px solid $c-blackmirror-blue;
    color: $c-blackmirror-blue;

    transition: border-color 0.3s ease-out, color 0.3s ease-out;

    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.7px;

    background-color: transparent;

    cursor: pointer;
    outline: none;

    &:hover {
      color: $c-blue;
      border-color: $c-blue;
    }
  }
}

</style>
