<template>
  <div class="popup-container">
    <div class="popup-instructions">
      <h4 class="uppercase medium blue">Preparati a guardare la realtà da un altro punto di vista:<br>inizia ruotando il tuo telefono in verticale.</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RotatePopup'
}
</script>

<style scoped lang="scss">
@import "variables";

.popup-container {
  text-align: center;

  position: fixed;
  z-index: 99999;

  height: 100%;
  width: 100%;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  background-color: $c-black;
}

.popup-instructions {
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;

  left: 50%;
  top: 50%;
}

</style>
