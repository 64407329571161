import Platform from './platform'

export default {
  // avoid touching the actual element on the HTML
  // if this is a prerenderer
  el: !Platform.prerenderer ? '#app-loading-container' : null,
  created: function () {
    // do preloading stuff here

    let FILES_TO_PRELOAD = [
      '/static/img/bg.jpg',
      '/static/img/mask.png',
      '/static/gif/noise.gif',
      '/static/lottie/images/img_9.png',
      '/static/lottie/images/img_5.png',
      '/static/lottie/images/img_6.png',
      '/static/lottie/images/img_14.png',
      '/static/lottie/images/img_13.png'
    ]

    let LOADED_IMAGES = []

    for (let i = 0; i < FILES_TO_PRELOAD.length; i++) {
      let p = new Promise((resolve, reject) => {
        let img = new Image()

        img.onload = resolve
        img.onerror = reject

        img.src = FILES_TO_PRELOAD[i]
      })

      LOADED_IMAGES.push(p)
    }

    Promise.all(LOADED_IMAGES).then((result) => {
      this.visible = false
    })
  },
  data: function () {
    return {
      visible: true
    }
  }
}
