<template>
  <div
    id="app"
    v-show="!platform.prerenderer && isReady"
    :version="version"
    :commit-hash="commitHash"
    :deploy-flag="deployFlag"
    :target-domain="targetDomain"
    >

    <!-- <SiteHeader /> -->
    <!-- <GlitchCanvas /> -->

    <CookieBanner />
    <router-view class="page" v-if="isReady" :secondvisit="secondVisit"/>
    <SiteFooter />
    <VersionInfo :version="version" v-if="!deployFlag" :commit-hash="commitHash" />
    <transition>
      <RotatePopup v-if="!platform.prerenderer && rotatePopup" />
    </transition>
  </div>
</template>

<script>
import VersionInfo from '@/atoms/VersionInfo.vue'
// import SiteHeader from '@/organisms/SiteHeader'
import SiteFooter from '@/organisms/SiteFooter'
import Platform from '@/platform'
import Viewport from '@/mixins/Viewport'
import RotatePopup from '@/atoms/RotatePopup.vue'
import CookieBanner from '@/atoms/CookieBanner'
import VueCookie from 'vue-cookie'

// import GlitchCanvas from '@/atoms/GlitchCanvas'

export default {
  name: 'App',

  components: {
    VersionInfo,
    // SiteHeader,
    RotatePopup,
    SiteFooter,
    CookieBanner
    // GlitchCanvas
  },

  mixins: [Viewport],

  props: {
    // version string of this specific build
    version: {
      type: String,
      required: true
    },
    // commit hash of this specific build
    commitHash: {
      type: String,
      required: true
    },
    // this is the domain where the site will stay
    // it varies from build to build so the site can be built to be hosted
    // on various domains
    // it can be set by using
    // npm run build --domain="http://www.example.com"
    targetDomain: {
      type: String,
      required: true
    },
    // this is a flag set to true
    // by the build system (or if you build the site with --deploy)
    // use this to enable debug stuff that needs to be hidden in the deploy version
    deployFlag: {
      type: Boolean,
      required: true
    },
    // the preloader instance used by the root app
    // set preloader.visible to show it / hide it
    preloader: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false,
      rotatePopup: false,
      secondVisit: false,
      // platform, accessible with an import statement
      // or by using this.$root.platform
      // NOTE: import statement is preferred
      platform: Platform
    }
  },

  mounted: function () {
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
    if (!Platform.prerenderer) {
      this.secondVisit = VueCookie.get('second-visit') === 'true'
    }
    VueCookie.set('second-visit', true, 7)
  },

  watch: {
    viewPort (value) {
      var screenHeigth = 0
      var screenWidth = 0

      if (Platform.ipad || Platform.iphone) {
        screenHeigth = this.viewPort.height
        screenWidth = this.viewPort.width
      } else {
        screenHeigth = screen.height || this.viewPort.height
        screenWidth = screen.width || this.viewPort.width
      }

      this.rotatePopup = (Platform.mobile) && (screenWidth > screenHeigth && screenWidth <= 1199)
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: ' ',
      // all titles will be injected into this template
      titleTemplate: this.$gettext('Black Mirror - The Black game'),
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'fragment', content: '!' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'msapplication-tap-highlight', content: 'no' },
        { name: 'msapplication-TileColor', content: '#100E17' },
        { name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
        { name: 'theme-color', content: '#100E17' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@monogridstudio' },
        { name: 'twitter:creator', content: '@monogridstudio' },
        // generic description
        { vmid: 'description', name: 'description', content: this.$gettext('A Vue.js Boilerplate buit and mantained by MONOGRID') },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: this.targetDomain + this.$route.fullPath + '/' },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'MONOGRID Digital & VR Creative Studio' },
        { vmid: 'ogdescription', property: 'og:description', content: this.$gettext('A Vue.js Boilerplate buit and mantained by MONOGRID') },
        { vmid: 'ogimage', property: 'og:image', content: this.targetDomain + '/static/img/share.jpg' }
      ],
      htmlAttrs: {
        lang: this.$language.current
      }
    }
  }
}
</script>

<style lang="scss">

// either use this (to normalize css)
// @import '~normalize-scss';
// or this (to reset css)
@import '~node-reset-scss/scss/reset';
// IMPORTANT: don't import both

@import 'variables';
@import 'fonts';

//
//
// Default app CSS layout
// NOTE: please change this to fit your needs
//
//
html {
  height: 100%;
  width: 100%;
  font-family: $font-netlix;
  font-weight: 300;
  color: $c-white;
  background-color: $c-black;
  overflow: hidden;
  overflow-x: hidden;
}

.page {
  z-index: $z-content;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}

//
//
// Default styles
// NOTE: delete these and re-write them in your app
//
//
h1 {
  font-weight: 700;
  font-size: 3rem;
  margin: 1rem;

  @include breakpoint($xs) {
    font-size: 2rem;
  }
}

h2 {
  font-weight: 400;
  font-size: 2rem;
  margin: 0.5rem;

  @include breakpoint($xs) {
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 18px;
  font-weight: 100;
  line-height: 24px;

  &.medium {
    font-weight: 500;

    @include breakpoint($xs) {
      font-size: 12px;
    }
  }

  &.blue {
    color: $c-blackmirror-blue;
  }

  @include breakpoint($xs) {
    font-size: 10px;
    line-height: 12px;
  }

  @include breakpoint($desktop-height-xs) {
    font-size: 10px;
    line-height: 12px;
  }
}

p {
  margin: 0.5rem 0;
}

a {
  color: $c-white;
  text-decoration: none;

  &:hover {
    color: $c-blue;
  }
}
.uppercase {
  text-transform: uppercase;
}
strong,
em {
  font-weight: 600;
}

button,
input,
select {
  border: 0;
  padding: 0.2rem;
  background-color: $c-white;
}

//
//
// this is the default transition
// when a component is enclosed in a <transition> tab with no "name" specified
// this is the transition which is triggered by default
//
//
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}
</style>
