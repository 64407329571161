<template>
  <transition appear name="slide-in">
    <footer class="footer">
      <div class="footer-left">
        <a target="_blank" href="https://www.facebook.com/NetflixFilmItalia/"><FacebookIcon class="facebook-icon"></FacebookIcon></a>
        <a target="_blank" href="https://www.instagram.com/netflixit/"><InstagramIcon class="instagram-icon"></InstagramIcon></a>
      </div>
      <a target="_blank" href="https://www.netflix.com/it/"><NetflixIcon class="netflix-icon"></NetflixIcon></a>
    </footer>
  </transition>
</template>

<script>
import NetflixIcon from '@/assets/svg/logo-netflix.svg'
import InstagramIcon from '@/assets/svg/ic-social-instagram.svg'
import FacebookIcon from '@/assets/svg/ic-social-facebook.svg'
export default {

  name: 'SiteFooter',

  components: {
    NetflixIcon,
    InstagramIcon,
    FacebookIcon
  },

  methods: {
  },

  computed: {
  }

}
</script>

<style scoped lang="scss">
@import 'variables';

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $z-header-footer;
  box-sizing: border-box;
  padding: 1rem 2rem;
  font-size: 1rem;
  color: $c-white;
  display: inline-block;

  @include breakpoint($xs) {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }

  .netflix-icon {
    position: absolute;
    height: 45px;
    right: 30px;
    bottom: 30px;
    width: 110px;

    @include breakpoint($xs) {
      height: 30px;
      right: 5px;
      transform: none;
      bottom: 5px;
    }
  }

  .footer-left {
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: 120px;

    @include breakpoint($xs) {
      bottom: 5px;
      left: 25px;
      margin-left: 0;
    }

    a {
      display: inline-block;
      width: 40px;

      @include breakpoint($xs) {
        width: 30px;
      }
    }
  }
  .instagram-icon,
  .facebook-icon {
    height: 30px;
    fill: $c-blackmirror-blue;

    @include breakpoint($xs) {
      height: 25px;
    }
  }
}
</style>
